<script>
	import mapboxgl from "mapbox-gl";
	import Api from "../services/Api";
	import { getTime } from "../services/GetTime";
	import { onMount } from 'svelte'
	// import { MarkerStore, UpdateMarkers } from '../stores/_MarkerStore'
	mapboxgl.accessToken = 'pk.eyJ1IjoibWFyY2luZW0iLCJhIjoiY2tvZXI5dm5sMDd5MjJvbHp0ODlha3IwciJ9._2Kt3wR9Z5iYfjMIcm7t4Q'
	
	import { createEventDispatcher } from 'svelte'
    let dispatch = createEventDispatcher()

	$: markers = []
	$: popups = []
	var map = null
	let token = localStorage.getItem("token")
	let name = localStorage.getItem("user")

	export const updateStalk = (data) => {
		let color = localStorage.getItem('color') 
		data.forEach(element => {
			markers.filter((marker) => {
				if(marker._popup._content.childNodes[0].innerHTML === element["name"]){
					let query_popup = popups.filter(popup => popup._content.childNodes[0].innerHTML === element["name"])
					marker.setLngLat([element["longitude"], element["latitude"]])
					if(query_popup[0]._content.childNodes[0].innerHTML === name){
						marker.setPopup(query_popup[0].setHTML(`${getTime(name, element["time_stamp"])}<input type="color" id="favcolor" value=#${color}><button id="set_color">Zmień kolor</button>`))
					}else{
						marker.setPopup(query_popup[0].setHTML(getTime(element["name"], element["time_stamp"])))
					}
				}
			})
		})
	}
	export const updateBroadcast = (lat, lon, time_stamp) => {
		let color = localStorage.getItem('color')
		// MarkerStore.subscribe(data => {
		// 	markers = data[0]
		// 	popups = data[1]
		// }) 
		markers.filter((marker) => {
			if(marker._popup._content.childNodes[0].innerHTML === name){
				let query_popup = popups.filter(popup => popup._content.childNodes[0].innerHTML === name)
				marker.setLngLat([lat, lon])
				marker.setPopup(query_popup[0].setHTML(`${getTime(name, time_stamp)}<input type="color" id="favcolor" value=#${color}><button id="set_color">Zmień kolor</button>`)
			)}
		})

	} 
	
	onMount(() => {
		map = new mapboxgl.Map({
			container: "map",
			style: "mapbox://styles/mapbox/streets-v11",
			center: [20.955, 52.243],
			zoom: 13,
		});

		Api.get('stalkers', token)
			.then(res => {
				res.forEach(element => {
					let popup
					if (element["name"] === name){
						localStorage.setItem("timestamp", element['time_stamp'])
						localStorage.setItem("color", element['color'])
						popup = new mapboxgl.Popup({ offset: 25 })
							.setHTML(`${getTime(name, element["time_stamp"])}<input type="color" id="favcolor" value=#${element["color"]}><button id="set_color">Zmień kolor</button>`)
					}else{
						
						popup = new mapboxgl.Popup({ offset: 25 })
							.setHTML(getTime(element["name"], element["time_stamp"]))
					}

					let marker = new mapboxgl.Marker({color: `#${element["color"]}`})
					.setLngLat([element["longitude"], element["latitude"]])
					.setPopup(popup)
					.addTo(map);
					markers.push(marker)
					popups.push(popup)

				});
				// UpdateMarkers([markers, popups])
				

				// Change color
				let _marker = document.querySelectorAll('.mapboxgl-marker')
				for (let i = 0; i < _marker.length; i++) {
					_marker[i].addEventListener("click", function() {
						setTimeout(function(){
							let _popup = document.querySelector('.mapboxgl-popup')
							let _name = _popup.querySelector('div > p').innerHTML
							if(_name === name){
								let _btn = _popup.querySelector('button#set_color')
								_btn.addEventListener("click", () => {
									let _color = _popup.querySelector('#favcolor')
									let new_color = _color.value.substr(1)

									const request = {
										name,
										color: new_color
									}
									Api.post(`stalkers/${name}/color?_method=PUT`, request, token)
									.then(() => {
										console.log('changing color...')
										location.reload()
									})
									.catch(err => {
										console.log(err)
									});
								})
							}
						}, 100);
					});
				}
				
			})
			.catch(err => {
				console.log(err)
        });
	})
	
	const handleZoom = () => {
		let opened_popup = popups.filter((popup) => popup.isOpen() === true)
		if(opened_popup.length > 0){
			let query_marker = markers.filter(marker => marker._popup._content.firstChild.innerHTML === opened_popup[0]._content.childNodes[0].innerHTML)
			map.flyTo({
				center: [query_marker[0].getLngLat().lng, query_marker[0].getLngLat().lat],
				zoom: 17
			})
		}else{
			if(markers.length === 1){
				map.flyTo({
					center: [markers[0].getLngLat().lng, markers[0].getLngLat().lat],
					zoom: 17
				})
			}
			if(markers.length > 1){
				const marker_list = []
				markers.forEach((marker) =>{
					marker_list.push(marker.getLngLat()) 
				})
				map.fitBounds(marker_list, {padding: 40});
			}
		}
	}
	
	

	
	
	const changeColor = () => {
		let color = document.querySelector('#favcolor').value
		const request = {
			name,
			color,
		}
		Api.post(`stalkers/${name}/color?_method=PUT`, request, token)
		.then(() => {
			console.log('changing color...')
			location.reload()
		})
		.catch(err => {
			console.log(err)
		});
	}

	export const handleLogout = () => {
		console.log('logging out')
		dispatch('logout')
		Api.get('logout', token)
	}
	

</script>

<div class="map_wrapper">
	<div id="logout" on:click={handleLogout}></div>
	<div id="zoom" on:click={handleZoom} ></div>
	<div id="map"></div>
</div>
	

<style>

:root {
    --blue: rgb(41, 171, 226);
    --grey: rgb(66, 66, 66);
    --light_grey: rgb(230, 230, 230);
}

</style>