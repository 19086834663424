import axios from "axios";

const axiosAPI = axios.create({
    baseURL : "https://stalker.onebold.studio/api/" // it's not recommended to have this info here.
  });

const apiRequest = (method, url, request, token) => {
    const headers = {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`,
    };
    //using the axios instance to perform the request that received from each http method
    return axiosAPI({
        method,
        url,
        data: request,
        headers
        })
        .then(res => {
            return Promise.resolve(res.data);
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

// function to execute the http get request
const get = (url, token) => apiRequest("get", url, '', token);

// function to execute the http delete request
const deleteRequest = (url, request) =>  apiRequest("delete", url, request);

// function to execute the http post request
const post = (url, request, token) => apiRequest("post", url, request, token);

// function to execute the http put request
const put = (url, request, token) => apiRequest("put", url, request, token);

// function to execute the http path request
const patch = (url, request) =>  apiRequest("patch", url, request);

// expose your method to other services or actions
const API ={
    get,
    delete: deleteRequest,
    post,
    put,
    patch
};
export default API;