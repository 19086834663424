<script>
	import Map from './components/Map.svelte'
	import Nav from './components/Nav.svelte'
	import Login from './components/Login.svelte'
	import Api from "./services/Api";
	// import { MarkerStore, UpdateMarkers } from './stores/_MarkerStore'

	let isLogin = false
	let user = localStorage.getItem("user")
	let token = localStorage.getItem("token")
	let map
	
	if(token && user){
		Api.get('stalkers', token)
			.catch(err => {
				console.log(err)
				isLogin = true
			});
	}else{
		isLogin = true
	}
	const isLoggedIn = (e) => {
		isLogin = !e.detail.login
		localStorage.setItem('user', e.detail.user)
		localStorage.setItem('token', e.detail.token)
	}
	const setBroadcast = (e) => {
		map.updateBroadcast(e.detail[0], e.detail[1], e.detail[2])
	}
	const setStalk = (e) => {
		map.updateStalk(e.detail)
	}
	const handleLogout = () => {
		isLogin = true
	}

</script>
<div>
	{#if isLogin}
		<Login on:login={isLoggedIn} />
	{:else}
		<Map bind:this={map} on:logout={handleLogout}/>
		<Nav on:broadcast={setBroadcast} on:stalk={setStalk}/>
	{/if}
</div>

<style>
	
</style>