<script>
	import Api from "../services/Api";
	import { createEventDispatcher } from 'svelte'

	let dispatch = createEventDispatcher()
	
	let name
	let password
	const handleLogin = (e) => {
		const request = {
			name,
			password,
		}
        Api.post('login', request)
			.then(res => {
				console.log(res)
				console.log('Logged in')
				const data = {
					login: true,
					token: res.token,
					user: res.user
				}
				dispatch('login', data)
			})
			.catch(err => {
				console.log('ERROR')
				console.log(err)
			});
    }
</script>


<div class="blur blur-login">
	<div class="login">
		<div class="title">Login</div>
		<form name="login">
		<!-- <form onSubmit={handleSubmit} name="login"> -->
			<input
				type="text"
				placeholder="Username"
				id="username"
				bind:value={name}/>
			<input
				type="password"
				placeholder="Password"
				id="password"
				bind:value={password}/>
			<button class="btn" type="submit" id="submit_login" on:click|preventDefault={handleLogin}>login</button>
			<!-- {loading
			?
			<button class="btn" type="submit" id="submit_login" disabled>logging...</button>
			:
			<button class="btn" type="submit" id="submit_login">login</button>
			} -->
		</form>
		<!-- {error && <div>{error['message']}</div>} -->
	</div>
</div>
	

<style>

.blur {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.5);
    animation: blur 1s ease;
    z-index: 50;
}
.blur.blur-login{
    z-index: 100;
}
.login{
    display: flex;
    flex-direction: column;
    width: 50%;
    max-height: 90%;
    background-color: white;
    z-index: 20;
    animation: login 1s ease;
}
form[name="login"]{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}
form[name="login"] input{
    height: 50px;
    margin-bottom: 20px;
    padding-left: 10px;
}
.title{
    margin: 20px 0 5px 20px;
    border-bottom: 1px solid var(--light_grey);
    font-size: 1.5em;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.btn{
    cursor: pointer;
    width: 150px;
    height: 50px;
    background-color: var(--blue);
    color: white;
    font-weight: 700;
    text-align: center;
    line-height: 50px;
    border: none;
}
.btn:disabled{
    cursor: default;
    opacity: .5;
}

</style>