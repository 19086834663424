<script>
	import Api from "../services/Api"

	import { createEventDispatcher } from 'svelte'
    let dispatch = createEventDispatcher()

	let broadcasting
	let stalking
	let broadcast_mode = false
	let token = localStorage.getItem("token")
	let name = localStorage.getItem("user")
	

	const errorCallback = (error) => {
		if (error.code === 1) {
			alert('You need to enable gps')
		}
	}

	const handleSt = () => {
		Api.get(`stalkers`, token)
			.then(res => {
				console.log('stalking...')

				dispatch('stalk', res)
				
			})
			.catch(err => {
				console.log(err)
			})
	}
	const handleBc = () => {
		navigator.geolocation.getCurrentPosition((position) => {
			let lat = position.coords.latitude;
			let lon = position.coords.longitude;

			const request = {
				name,
				latitude: lat,
				longitude: lon,
			}
			Api.post(`stalkers/${name}?_method=PUT`, request, token)
			.then(res => {
				console.log('broadcasting...')
				dispatch('broadcast', [request["longitude"], request["latitude"], res['time_stamp']])
			})
			.catch(err => {
				console.log(err)
        	});
		
		}, errorCallback, {
			maximumAge: 0, timeout: 4000, enableHighAccuracy: true
		})
		
	}
	const handleStop = (mode) => {
		if(mode === 'broadcasting'){
			clearInterval(broadcasting);
			broadcasting = null;
			console.log('stopping broadcasting')
		}else if(mode === 'stalking'){
			clearInterval(stalking);
			stalking = null;
			console.log('stopping stalking')
		}
	}

	const handleStInterval = () => {
		if(!stalking){
			stalking = setInterval(() => {
				handleSt()
			}, 5 * 1000);
		}
	}

	const handleBcInterval = () => {
		if(!broadcasting){
			broadcasting = setInterval(() => {
				handleBc()
			}, 5 * 1000);
		}
	}
</script>


<div class="nav">

	<div id="broadcast_menu" class={broadcast_mode ? "broadcast" : "broadcast hide"}>
		<div class="rows">
			<button id="q_bc_btn" on:click={handleBc}>Udostępnij pozycję</button>
		</div>
		<div class="rows">
			<button id="bc_btn" class={broadcasting ? "active" : ""} on:click={handleBcInterval}>
				<img class={broadcasting ? "active_btn" : "start_stop_btns"} id="bc_start_icon" src="http://stalker.onebold.studio/public/front-svelte/img/start.svg" alt="">
			</button>
			<button id="stop_bc_btn" on:click={()=>{handleStop('broadcasting')}}>
				<img class="start_stop_btns" id="bc_stop_icon" src="http://stalker.onebold.studio/public/front-svelte/img/stop.svg" alt="">
			</button>
		</div>
	</div>

	<div id="stalk_menu" class={broadcast_mode ? "stalk hide" : "stalk"}>
		<div class="rows">
			<button id="q_st_btn" on:click={handleSt}>Stalkuj</button>
		</div>
		<div class="rows">
			<button id="st_btn" class={stalking ? "active" : ""} on:click={handleStInterval}>
				<img class={stalking ? "active_btn" : "start_stop_btns"} id="st_start_icon" src="http://stalker.onebold.studio/public/front-svelte/img/start.svg" alt="">
			</button>
			<button id="stop_st_btn" on:click={()=>{handleStop('stalking')}}>
				<img class="start_stop_btns" id="st_stop_icon" src="http://stalker.onebold.studio/public/front-svelte/img/stop.svg" alt="">
			</button>
		</div>
	</div>

	<div class="switch">
		<button id="sw_st" class={broadcast_mode ? "" : "active_mode"} on:click={()=>{broadcast_mode = false}}><img id="stalk_icon" class={broadcast_mode ? "opacity" : ""} src="http://stalker.onebold.studio/public/front-svelte/img/eye.svg" alt=""></button>
		<button id="sw_bc" class={broadcast_mode ? "active_mode" : ""} on:click={()=>{broadcast_mode = true}}><img id="broadcast_icon" class={broadcast_mode ? "" : "opacity"} src="http://stalker.onebold.studio/public/front-svelte/img/broadcast.svg" alt=""></button>
	</div>
</div>
	

<style>
	
</style>