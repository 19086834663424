export const getTime = (name, timestamp) =>{
    let now = Math.round(Date.now() / 1000)
    var seconds = now - timestamp;
    if (seconds < 0){
        seconds = 0
    }
    var minutes = parseInt(Math.floor(seconds / 60), 10);
    var hours = parseInt(Math.floor(minutes / 60), 10);
    var days = parseInt(Math.floor(hours / 24), 10);

    if (seconds < 60){
        return `<p>${name}<p><p>Ostatni update: ${seconds}s temu</p>`
    }else
    if (minutes < 60){
        return `<p>${name}<p><p>Ostatni update: ${minutes}m temu</p>`
    }else
    if (hours < 24){
        return `<p>${name}<p><p>Ostatni update: ${hours}h temu</p>`
    }else
    return `<p>${name}<p><p>Ostatni update: ${days}d temu</p>`
}